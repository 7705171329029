<template>
  <div v-if="$hasFonction(user, 'Voir compte')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <b-card no-body title="" class="mt-2">
        <div class="my-2 mx-2 row">
          <!-- search start -->
          <div class="blockSearch col-lg-8">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Recherche"
              class="search col4 mr-5"
              style="width: 40%"
            >
            </b-form-input>
          </div>
          <!-- search end -->

          <!-- bouton export start -->
          <div class="col-lg-4">

            <b-button
            v-if="$hasFonction(user, 'Exporter compte')"
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>

          <b-button
            v-if="$hasFonction(user, 'Exporter compte')"
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>

          </div>
         
          <!-- bouton export end -->
        </div>

        <!-- table start -->
        <b-table
          v-model="ExcelComptes"
          id="my-table"
          responsive
          :fields="headers"
          :items="comptes"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          hover
          striped
          @row-clicked="onRowClicked"
          @filtered="onFiltered"
          show-empty
          class="border-bottom"
        >
          <!-- iconAction start -->
          <template #cell(solde)="data">
            {{ $thousandSeparator(data.item.solde) }}
          </template>

          <template #empty>
            <div class="text-center">
              <span class="text-nowrap">Aucune donnée existante</span>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="text-center">
              <span class="text-nowrap"
                >L'élément recherché n'est pas dans la liste</span
              >
            </div>
          </template>
        </b-table>
        <!-- table end -->

        <div class="my-2 mx-2 row">
          <!-- per-page end -->
          <div class="col">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 70px"
            ></b-form-select>
            <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	              <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                </span>
            <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
          </div>
          <!-- per-page end -->

          <!-- pagination start -->
          <b-pagination
            class="pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- pagination end -->
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import vSelect from "vue-select";

import {
  BCard,
  BTable,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPencilFill,
  BIconTrashFill,
  BIconPlus,
  BIconCircleFill,
  BIconArrowClockwise,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCollapse,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BCard,
    BTable,
    BModal,
    BCardText,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      etatButton: true,
      add: false,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      isOperating: false,
      required,
      integer,
      alpha,
      alphaDash,
      libelleState: null,
      errorsLibelle: null,
      errorsDevise: null,
      errorsLibelleModal: null,
      errorsDeviseModal: null,
      modalEdit: false,
      // totalRows: 1,
      perPage: 10,
      allRows: 0,
      currentPage: 1,
      filter: null,
      pageOptions: [],
      comptes: [],
      ExcelComptes: null,
      editedForm: {},
      defaultForm: {},
      devises: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },
    headers() {
      let userHeader = [
        { label: "N°", align: "start", key: "id", sortable: true },
        { label: "Libellé", align: "start", key: "libelle", sortable: true },
        { label: "Devise", align: "start", key: "devise", sortable: true },
        { label: "Actions", align: "start", key: "actions" },
      ];
      return userHeader;
    },
  },
  created() {
    this.getComptes();
  },
  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    onRowClicked(item, index, event) {
      this.$router.push({
        name: "archives/compte-details",
        params: { id: item.id },
      });
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
        totalRows()
      },

    getComptes() {
      this.$http
        .get("/comptes")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }
          this.comptes = res.data.data;
          this.allRows = this.comptes.length
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelComptes.forEach((element) => {
        obj = {
          "DATE D'ANNULATION": element.created_at,
          CLIENTS: element.client_name,
          ASSOCIATION: element.association,
          "MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
          "TOTAL PERÇU": element.total_percu ? element.total_percu : 0,
          "MONTANT DÉDOMAGÉ": element.dedomagement ? element.dedomagement : 0,
          BÉNÉFICE: element.benefice ? element.benefice : 0,
          OBSERVATIONS: element.observation ? element.observation : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Comptes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.comptes.forEach((element) => {
        obj = {
          "DATE D'ANNULATION": element.created_at,
          CLIENTS: element.client_name,
          ASSOCIATION: element.association,
          "MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
          "TOTAL PERÇU": element.total_percu ? element.total_percu : 0,
          "MONTANT DÉDOMAGÉ": element.dedomagement ? element.dedomagement : 0,
          BÉNÉFICE: element.benefice ? element.benefice : 0,
          OBSERVATIONS: element.observation ? element.observation : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Comptes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>