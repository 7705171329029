<template>
	<div 
		class="img mb-5"

	>
		<b-img
			fluid
			:src="emptyListImg"
			alt="Empty list Image"
			style="width: 250px; !important;"
		/>
		<span class="mt-1 d-flex justify-content-center">Aucune donnée(s) pour le moment </span>
	</div>
</template>

<script>
	import {
		BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
	} from 'bootstrap-vue'

	export default {
		components: {
			BRow,
			BCol,
			BLink,
			BFormGroup,
			BFormInput,
			BInputGroupAppend,
			BInputGroup,
			BCardText,
			BCardTitle,
			BImg,
			BForm,
			BButton,
		},
		data() {
			return {
      			emptyListImg: require('@/assets/images/pages/empty-list.png'),
			}
		}
	}
</script>

<style>
</style>
